import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import PageHeader from "../components/pageSections/pageHeader"
import ParagraphTabs from "../components/pageSections/paragraphTabs"

import ParagraphText from "../components/pageSections/paragraphText"
import TitleWithDescription from "../components/pageSections/titleWithDescription"
import QuotedText from "../components/pageSections/quotedText"
import ParagraphInset from "../components/pageSections/paragraphInset"
import GreyQuotedText from "../components/pageSections/greyQuotedText"
import ParagraphImage from "../components/pageSections/paragraphImage"

const AboutInspiration = (props) => {
const { data } = props
let pageTitle = ''
let pageInfo = {}
let components = []
let metaTags = []
let altText = ""
let imageSrc = ""
let imageSrc2 = ""
let altText2 = ""
if (data.allNodeTemplatePages && data.allNodeTemplatePages.edges) {
  pageInfo = data.allNodeTemplatePages.edges[0]
  pageTitle = pageInfo?.node?.title ? pageInfo.node.title : ''
  components = pageInfo?.node?.relationships?.components ? pageInfo.node.relationships.components : []
  console.log('pageInfo=pageInfo=pageInfo=',components)
  metaTags = pageInfo?.node?.metatags ? pageInfo.node.metatags : []
  altText = components && components[1] ? components[1]?.image?.alt : "Hinduja Hospital"
  //imageSrc = components && components?.[1] ? components[1].relationships?.image.uri?.url : null

  altText2 = components && components[9] ? components[9]?.image?.alt : "Hinduja Hospital"
  imageSrc2 = components && components?.[10] ? components[10].relationships?.image.uri?.url : null
}
const defaultImageSrc = "https://via.placeholder.com/221x316"

let blue_section;
const b = components.find(c => c.name === 'blue_background_content');
if(b && b.relationships && b.relationships.components && b.relationships.components.length > 0){
    blue_section = b.relationships.components[0];
}
return (
<Layout>
    <Meta
    files={
    {
    js: [],
    css: ["/assets/css/about.css", "/assets/css/about-through-the-ages.css"]
    }
    }
    tags={metaTags}
    />
    
    <main className="innerpage">
       
        <section className="section_bg pt-first-section pb-section about-ages">
            <div className="container">
                <div className="section-head mb-0">
                    <PageHeader
                        title={pageTitle}
                        />
                    {
                    // (components && components[0]) &&
                    // <ParagraphText
                    //     nocol={true}
                    //     content={components[0]}
                    //     />
                    }
                </div>
            </div>
            <ParagraphTabs
                content={components}
                />
        </section>
        {/* <section className="section_bg plus_icon about_section about_section_inner">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-4">
                        <figure className="about_img">
                            {
                            imageSrc ?
                            <img
                                src={imageSrc}
                                alt={altText}
                                />
                            : <img className="lazyload" 
                                src={defaultImageSrc}
                                alt={altText} />
                            }
                        </figure>
                    </div>
                    <div className="col-md-8 col-8">
                        <div className="about_content">
                            {
                            (components && components[2]) &&
                            <QuotedText
                            content={components[2]?.quote ? components[2].quote : ''}
                            />
                            }
                            <div className="full_onmobile">
                                {
                                (components && components[3]) &&
                                <TitleWithDescription
                                    content={components[3]}
                                    head={3}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="para_text blueboxWrapNone">
                    {
                    (components && components[4]) &&
                    <ParagraphInset
                        content={components[4]}
                        />
                    }
                </div>
                {blue_section &&
                    <div className="row blueboxWrapNone">
                        <div className="col-md-4">
                            {blue_section?.text?.processed &&
                                <div className="bluebox br-10" dangerouslySetInnerHTML={{ __html: blue_section?.text?.processed }}></div>
                            }
                        </div>
                        <div className="col-md-8">
                            {blue_section?.field_text?.processed &&
                                <div dangerouslySetInnerHTML={{ __html: blue_section?.field_text?.processed }}></div>
                            }
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-12">
                        {
                        (components && components[6]) &&
                        <GreyQuotedText
                            content={components[6]}
                            />
                        }
                    </div>
                </div>
                <div className="para_text">
                    {
                    (components && components[7]) &&
                    <ParagraphText
                        content={components[7]}
                        />
                    }
                </div>
                <div className="blueboxWrapNone">
                  {
                  (components && components[8]) &&
                  <ParagraphImage
                      content={components[8]}
                      />
                  }
                </div>  
                <div className="para_text">
                    {
                    (components && components[9]) &&
                    <ParagraphText
                        content={components[9]}
                        />
                    }
                </div>
                
                <div className="row">
                    <div className="col-md-4 col-4">
                        <figure className="about_img">
                            {
                            imageSrc2 ?
                            <img
                                src={imageSrc2}
                                alt={altText2}
                                />
                            : <img className="lazyload" 
                                src={defaultImageSrc}
                                alt={altText2} />
                            }
                        </figure>
                    </div>
                    <div className="col-md-8 col-8">
                        <div className="about_content">
                            {
                            (components && components[11]) &&
                            <QuotedText
                            content={components[11]?.quote ? components[11].quote : ''}
                            />
                            }
                            <div className="full_onmobile">
                                {
                                (components && components[12]) &&
                                <TitleWithDescription
                                    content={components[12]}
                                    head={12}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
    </main>
</Layout>
)
}
export const query = graphql`
query aboutInspirationPage {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/about-inspiration$/"}}}) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          
          relationships {
            components: field_component_type {
              __typename
              ...ParagraphHTMLTextInp
              ...ParagraphImageComponentInp
              ...ParagraphQuoteInp
              ...ParagraphTabComponentAges
              ...ParagraphInsetInp
              ...ParagraphQuote2Inp
              ...ParagraphImageTextInp
              ...ParagraphSectionInp
              ...ParagraphImageText
            }
          }
        }
      }
    }
  }
  
  fragment ParagraphImageComponentInp on paragraph__image_component {
    id
    image: field_image {
      alt
    }
    relationships {
      image: field_image {
        id
        uri {
          value
          url
        }
      }
    }
  }
  
  fragment ParagraphHTMLTextInp on paragraph__html_text {
    id
    htmlText: field_text {
      processed
    }
  }
  
  fragment ParagraphInsetInp on paragraph__inset {
    id
    inset: field_inset_text
  }
  
  fragment ParagraphQuoteInp on paragraph__quote {
    id
    quote: field_quotation
  }
  
  fragment ParagraphImageTextInp on paragraph__image_text {
    id
    text: field_text_demo {
      processed
    }
    image: field_image_demo {
      alt
    }
    relationships {
      image: field_image_demo {
        id
        uri {
          value
          url
        }
      }
    }
  }
  
  fragment ParagraphImageText on paragraph__image_text {
    id
    text: field_text_demo {
      processed
    }
    image: field_image_demo {
      alt
    }
    relationships {
      image: field_image_demo {
        id
        uri {
          value
          url
        }
      }
    }
  }

  fragment ParagraphSectionInp on paragraph__section {
    id
    name: field_name
    relationships {
      components: field_components {
        __typename
        ...ParagraphTitleAndDescriptionInp
      }
    }
  }
  
  fragment ParagraphTitleAndDescriptionInp on paragraph__title_and_description {
    id
    field_title
    text: field_description {
      processed
    }
    field_cta_link {
      title
      uri
    }
    field_text {
      processed
    }
  }
  
  fragment ParagraphQuote2Inp on paragraph__quote_variation_2 {
    id
    pre: field_pre_quote
    quote: field_quotation
    post: field_post_quote
  }

  fragment ParagraphTabComponentAges on paragraph__tab_components {
    id
    title: field_title
    relationships {
      components: field_components {
        __typename
        ...ParagraphInset
        ...ParagraphHTMLText
        ...ParagraphImageComponentAges
      }
    }
  }
`
export default AboutInspiration